<template>
  <div style="overflow-x: hidden;" class="my-10">
    <Loading :isLoading="isLoading"/>

    <div class="flex mb-8">
      <div style="display:table;">
        <div style="display:table-cell;vertical-align:middle;"><img src="@/assets/triangle.svg" style="width:30px;" alt="triangle"></div>
      </div>

      <div style="display:table;margin-left:10px;">
        <div style="display:table-cell;vertical-align:middle;">
          <label class="sub-title"> Registro de Identificación Oficial </label>
        </div>
      </div>
    </div>

    <div class="mx-8">
      <IdentificationRegister ref="IdentificationRegisterRef" :view_class_view="viewClassViewIdentificationRegister" :validations="ruleValidationsIdentificationRegister"  v-on:identification-register-data="identificationRegisterData($event)" />
    </div>

    <div class="flex mt-8">
      <div style="display:table;">
        <div style="display:table-cell;vertical-align:middle;">
          <img src="@/assets/triangle.svg" style="width:30px;" alt="triangle">
        </div>
      </div>

      <div style="display:table;margin-left:10px;">
        <div style="display:table-cell;vertical-align:middle;"><label class="sub-title"> Información personal </label></div>
      </div>
    </div>
    <PersonalInformation ref="PersonalInformationRef" :view_class_view="viewClassViewPersonalInformation" :validations="rulesValidationsPersonalInformation" :show_inputs="showInputsPersonalInformation" :buttons_styles="buttons_styles"   />

    <div class="flex"><div style="display:table;"><div style="display:table-cell;vertical-align:middle;"><img src="@/assets/triangle.svg" style="width:30px;" alt="triangle"></div></div><div style="display:table;margin-left:10px;"><div style="display:table-cell;vertical-align:middle;"><label class="sub-title"> Lugar de nacimiento </label></div></div></div>
    <BirthPlace ref="BirthPlaceRef" :key_token_storage="'login'" :urlAPI="ccApi" :view_class_view="viewClassViewPlaceOfBirth" :validations="rulesValidationsPlaceOfBirth"  :buttons_styles="buttons_styles" />

    <div class="flex"><div style="display:table;"><div style="display:table-cell;vertical-align:middle;"><img src="@/assets/triangle.svg" style="width:30px;" alt="triangle"></div></div><div style="display:table;margin-left:10px;"><div style="display:table-cell;vertical-align:middle;"><label class="sub-title"> Datos de contacto </label></div></div></div>
    <Contact ref="ContactRef" :validations="rulesValidationsContact" :view_class_view="viewClassViewPontact" :show_container="showContainerContact"  :buttons_styles="buttons_styles"  />

    <div class="flex"><div style="display:table;"><div style="display:table-cell;vertical-align:middle;"><img src="@/assets/triangle.svg" style="width:30px;" alt="triangle"></div></div><div style="display:table;margin-left:10px;"><div style="display:table-cell;vertical-align:middle;"><label class="sub-title"> Estado civil </label></div></div></div>
    <MaritalStatus ref="MaritalStatusRef" :validations="rulesValidationsMaritalStatus" :view_class_view="viewClassViewMaritalStatus" :show_container="show_container__marital_status"  :buttons_styles="buttons_styles"  />
    
    <div class="flex mt-20">
      <input type="button" class="cursor-pointer shadow-md rounded-lg w-full md:w-1/4 mx-auto px-3 py-3 mt-2 btn-next" @click="next()" value="Siguiente">
    </div>
  </div>
  
</template>

<script>
import Loading from '@/components/Loading/VueLoading.vue';

import { ref, onBeforeMount, onMounted, computed, watch } from 'vue';
import validations from '@/helpers/validations';
import { email, helpers, requiredIf, maxLength, minLength } from "@vuelidate/validators";
import AdmissionRequest from '@/classes/AdmissionRequest';
import Swal from "sweetalert2";
import SwalAlertHelper from '@/helpers/swal-alert';
import { useRouter } from 'vue-router';
import { getTokenDecoden } from "@/helpers/tokenauth";
import { get } from 'axios';
import Document from '@/classes/Document';
import User from "@/classes/User";

export default {

  name: 'AssistedEnrollmentStep1Component',

  setup() {
    const ccApi = process.env.VUE_APP_CCAPI;
    const router = useRouter();
    const documentObjectClass = ref(null);
    const userObjectClass = ref(null);

    const rulesValidations = ref({});
    const BirthPlaceRef = ref(null);
    const IdentificationRegisterRef = ref(null);
    const PersonalInformationRef = ref(null);
    const ContactRef = ref(null);
    const MaritalStatusRef = ref(null);

    let isLoading = ref(false);
    let matiClientId = process.env.VUE_APP_MATI_CLIENTID;
    let matiuserid = process.env.VUE_APP_MATI_ID_AND_BIOMETRICS;

    let isRequiredRfc = ref(true);
    let isRfcValid = ref(true);

    let isRequiredRfcOfMaritalStatus = ref(true);
    let isRfcOfMaritalStatusValid = ref(true);


    let viewClassViewPedentityVerification = {
        div_content_button: "w-full text-left my-5 ml-8",
    };

    let showInputsPersonalInformation = {
      rfc:true,
      curp:true,
      dateBirth:true,
      paternalName:true,
      maternalName:true,
      name:true,
      secondName:true,
      gender:true
    };

    let viewClassViewPersonalInformation = {
      input_class:{
        valid:"border-blue-600",
        no_valid:"border-red-600",
        default:"w-full py-2 px-2 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none "
      },
      div_container_error:{
        container:"my-2",
        text_error:"text-red-600",
      },
      div_container:"grid grid-cols-1 sm:grid-cols-3 sm:gap-5 my-8 ml-8",
    };

    let viewClassViewIdentificationRegister = {
      classInputs:{
        valid:"border-blue-600",
        no_valid:"border-red-600",
        default:""
      },
      div_container_error:{
        container:"my-2",
        text_error:"text-red-600",
      },
      div_container:"w-full",
    };

    let rulesValidationsPersonalInformation = {
      rfc:{
        required:helpers.withMessage("El campo rfc es requerido.",requiredIf(true)),
        minLength: helpers.withMessage("El campo debe contener mínimo 13 caracteres", minLength(13)),
        maxLength: helpers.withMessage("El campo debe contener máximo 13 caracteres", maxLength(13)),
        alreadyExists: helpers.withMessage("El RFC capturado ya existe en base de datos. Favor de capturar otro" , helpers.withAsync(async function (value) {
          const rfcIsValid = validateRfcStructure(value.toString().toUpperCase());
          if(rfcIsValid.status && value != "") {
            return await validateRfcAlreadyExists(value);
          }
          return true;
        })),
        isDinstinct: helpers.withMessage("El RFC del socio no puede ser el mismo que el del cónyuge" , function(value){          
          if(value == "" && MaritalStatusRef.value.maritalStatusModel.rfc == "")
            return true;
          return value == MaritalStatusRef.value.maritalStatusModel.rfc? false: true;
        }),
        structureIsValid: helpers.withMessage("El formato del RFC no es correcto. Ejemplo de formato correcto: LOMP8206281H0" , helpers.withAsync(async function (value, e) {
          if(value.length==0) {
            return true;
          }

          let rfc = validateRfcStructure(value);
          if(!rfc.status) return true;
          
          let validate = await validateStrucDatauserRFC();

          if(validate && e.curp == ""){
            e.curp = value.toString().substr(0, 10);
          }

          return validate;
        })),
        isValid: helpers.withMessage("El formato del RFC no es correcto. Ejemplo de formato correcto: LOMP8206281H0" , helpers.withAsync(async function (value) {
          if(value.length==0) {
            return true;
          }

           let rfc = validateRfcStructure(value);
           return rfc.status;
        }))
      },
      curp:{
        required: helpers.withMessage("El campo curp es requerido.",requiredIf(true)),
        minLength: helpers.withMessage(`El campo debe contener mínimo 18 caracteres`, minLength(18)),
        maxLength: helpers.withMessage(`El campo debe contener máximo 18 caracteres`, maxLength(18)),
        structureIsValid: helpers.withMessage("El formato del CURP no es correcto. Ejemplo de formato correcto: LOMP8206281HSRTZR02" , function (value) {
          return value == ""? true : validateCurpStructure(value.toString().toUpperCase());
        }),
        validateMatchBetweenCurpAndRfc: helpers.withMessage(`Los primeros 10 caracteres del campo CURP no coinciden con los primeros 10 caracteres del campo RFC`, function (value, e) {
          return (e.rfc != '') ? validateMatchStrings(value, e.rfc): true
        }),
      },
      dateBirth:{
        required:helpers.withMessage("El campo fecha de nacimiento es requerido.",requiredIf(true)),
      },
      paternalName:{
        required:helpers.withMessage("El campo apellido paterno es requerido.", requiredIf(async (value, e)=>{
          let rfc = validateRfcStructure(e.rfc);
          if(rfc.status) {
            await validateStrucDatauserRFC()
          }
          
          return e.maternalName == "" ? true : false;
        })),
        minLength: helpers.withMessage(`El campo debe contener mínimo 2 caracteres`,  minLength(2)),
        maxLength: helpers.withMessage(`El campo debe contener máximo 30 caracteres`,  maxLength(30)),
      },
      maternalName:{
        required:helpers.withMessage("El campo apellido materno es requerido.",requiredIf(async (value, e)=>{
          let rfc = validateRfcStructure(e.rfc);
          if(rfc.status) {
            await validateStrucDatauserRFC()
          }

          return e.paternalName == "" ? true : false;
        })),
        minLength: helpers.withMessage(`El campo debe contener mínimo 2 caracteres`,  minLength(2)),
        maxLength: helpers.withMessage(`El campo debe contener máximo 30 caracteres`,  maxLength(30)),
      },
      firstName:{
        required:helpers.withMessage("El campo primer nombre es requerido.",requiredIf(async (value, e)=> {
          let rfc = validateRfcStructure(e.rfc);
          if(rfc.status) {
            await validateStrucDatauserRFC()
          }

          return true;
        })),
        minLength: helpers.withMessage(`El campo debe contener mínimo 2 caracteres`,  minLength(2)),
        maxLength: helpers.withMessage(`El campo debe contener máximo 30 caracteres`,  maxLength(30))
      },
      secondName:{
        required:helpers.withMessage("El campo segundo nombre es requerido.", helpers.withAsync(async function (value, e) {
          let rfc = validateRfcStructure(e.rfc);
          if(rfc.status) {
            await validateStrucDatauserRFC()
          }
          
          return true;
        })),
        minLength: helpers.withMessage(`El campo debe contener mínimo 2 caracteres`,  function(value, obj){
          if(value != "" && value.length < 2) {
            return false
          }
          return true;
        }),
      },
      gender:{
        required:helpers.withMessage("el campo género es requerido.",requiredIf(true)),
      }
    };

    let viewClassViewPlaceOfBirth = {
      input_class:{
        valid:"border-blue-600",
        no_valid:"border-red-600",
        default:"w-full py-2 px-2 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none "
      },
      div_container_error:{
        container:"my-2",
        text_error:"text-red-600",
      },
      div_container:"flex flex-wrap mb-6 mt-5 ml-8",
      choose_button_nacionality:"flex flex-wrap w-full inline md:w-1/3 mb-6 md:mb-6",
      div_conatiner_states:"w-full md:w-1/3 px-3 mb-6 md:mb-6",
      div_conatiner_municipalities:"w-full md:w-1/3 px-3 mb-6 md:mb-6",
      div_conatiner_cities:"w-full md:w-1/3 px-3 mb-6 md:mb-6"
    };

    let rulesValidationsPlaceOfBirth = {
      country:{
        require : helpers.withMessage("El campo es requerido", requiredIf(()=>{
          return (BirthPlaceRef.value.showInputCountry);
        }))
      },
      state:{
        require : helpers.withMessage("El campo es requerido", requiredIf(()=>{
          return (!BirthPlaceRef.value.showInputCountry);
        }))
      },
      municipality:{
        require : helpers.withMessage("El campo es requerido", requiredIf(()=>{
          return (!BirthPlaceRef.value.showInputCountry);
        }))
      },
      city:{
        require : helpers.withMessage("El campo es requerido", requiredIf(()=>{
         
          return !BirthPlaceRef.value.showInputCountry && BirthPlaceRef.value.cities.length>0;
        }))
      }
    };

    let viewClassViewPontact = {
      input_class:{
        valid:"border-blue-600",
        no_valid:"border-red-600",
        default:"w-full py-2 px-2 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none "
      },
      div_container_error:{
        container:"my-2",
        text_error:"text-red-600",
      },
      div_conteiner_phone:{
        input_mask:"### ###-####"
      },
      div_conteiner_cellPhone:{
        input_mask:"### ###-####"
      },
      div_container:"grid grid-cols-1 sm:grid-cols-3 sm:gap-10 my-5 ml-8",
      label:"label-input w-full block tracking-wide mb-2"
    };

    const validateMatchStrings = (str, matchStr) => {
      str = str.toString().trim().toUpperCase().substr(0, 10);
      matchStr = matchStr.toString().trim().toUpperCase().substr(0, 10);

      return str === matchStr;
    }

    const isUniqueIdentificationNumber = async (value, e) => {
      // console.log("value",value)
      // console.log("e.passport_number",e.passport_number)
      // console.log("e.identification_number",e.identification_number)
      // console.log("e.document_type",e.document_type)
      
      if((e.document_type == "Pasaporte" && e.passport_number.trim().length < 10) || (e.document_type == "INE/IFE" && e.identification_number.trim().length < 13)){
        return true;
      }

      if(e.document_type == "Pasaporte" && e.passport_number.trim().length == 10){
        let identificationNumberExists = await documentObjectClass.value.identificationNumberVerification(getToken(), e.document_type.toString().toUpperCase(), e.passport_number.trim()).then(resp => resp.data.response.identification_number_exists).catch(() =>  true);
        // console.log("identificationNumberExists: ", identificationNumberExists)
        return !identificationNumberExists;
      }

      if(e.document_type == "INE/IFE" && e.identification_number.trim().length == 13){
        let identificationNumberExists = await documentObjectClass.value.identificationNumberVerification(getToken(), e.document_type.toString().toUpperCase(), e.identification_number.trim()).then(resp => resp.data.response.identification_number_exists).catch(() =>  true);
        return !identificationNumberExists;
      }
      
      return true;
    }

    let ruleValidationsIdentificationRegister = {
        identification_number:{
          required: helpers.withMessage("El campo es requerido",  requiredIf(function(value, e){
            // console.log(value)
            if(e.document_type == "INE/IFE"){
              e.passport_number = "";
              return true;
            }
            return false;
          })),
          minLength: helpers.withMessage("El campo debe contener mínimo 13 caracteres.", minLength(13)),
          maxLength: helpers.withMessage("El campo debe contener máximo 13 caracteres.", maxLength(13)),
          isUnique: helpers.withMessage("El número de identificación ya se encuentra registrado", helpers.withAsync(isUniqueIdentificationNumber)),
          $autoDirty: true 
        },
        passport_number: {
          required: helpers.withMessage("El campo es requerido",  requiredIf(function(value, e){
            // console.log(value)
            
            if(e.document_type == "Pasaporte"){
              e.identification_number = "";
              return true;
            }
            return false;
          })),
          minLength: helpers.withMessage("El campo debe contener mínimo 10 caracteres.", minLength(10)),
          maxLength: helpers.withMessage("El campo debe contener máximo 10 caracteres.", maxLength(10)),
          isUnique: helpers.withMessage("El número de identificación ya se encuentra registrado", helpers.withAsync(isUniqueIdentificationNumber)),
          $autoDirty: true 
        },
        document_type: {
          required: helpers.withMessage("El campo es requerido",  requiredIf(function(){
            return true;
          }))
        }
      }


    const isUnique = async (value) => {
      if((new RegExp("^[^@]+@[^@]+\.[a-zA-Z]{2,}$").test(value))){
        try {
          return await get(process.env.VUE_APP_CCAPI + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/email_with_account`, {
            params:{
              token_auth: getToken(),
              email: value
            }
          }, { 'Content-Type': 'application/json' })
          .then(res => {
              
            return !res.data.response.email_with_account;
          });
        } catch (error) {
          return false;
        }
      }else {
        return true;
      }
    }

    let rulesValidationsContact = {
      phone:{
        required: helpers.withMessage(`El campo es requerido` , requiredIf(function(){return false})),
        minLength: helpers.withMessage(`El campo debe contener mínimo 10 caracteres` , function(value){
            if(value==""){
              return true
            }

            value = value.toString().replace("-", "");
            value = value.toString().replace(" ", "");
            
            if(value.toString().length < 10){
              return false
            }

            return true
          }),
        maxLength: helpers.withMessage(`El campo debe contener máximo 10 caracteres` , function(value){
          value = value.toString().replace("-", "");
          value = value.toString().replace(" ", "");
          if(value.toString().length > 10){
            return false
          }
          return true
        }),
        isDiferentToCell: helpers.withMessage(`El número de teléfono debe ser diferente al número de celular` , function(value, e){
          if(value == "") return true;

          return value != e.cellPhone;
        })
        
      },
      cellPhone:{
        required: helpers.withMessage(`El campo es requerido` , requiredIf(function(){return true})),
        minLength: helpers.withMessage(`El campo debe contener mínimo 10 caracteres` , function(value){
            if(value==""){
              return true
            }

            value = value.toString().replace("-", "");
            value = value.toString().replace(" ", "");
            
            if(value.toString().length < 10){
              return false
            }

            return true
          }),
        maxLength: helpers.withMessage(`El campo debe contener máximo 10 caracteres` , function(value){
            value = value.toString().replace("-", "");
            value = value.toString().replace(" ", "");

            if(value.toString().length > 10){
              return false
            }

            return true
          })
      },
      email:{
        email: helpers.withMessage(`El valor no es una dirección de correo electrónico válida` , email), 
        required: helpers.withMessage(`El campo es requerido` , requiredIf(true)),
        minLength: helpers.withMessage(`El campo debe contener mínimo 5 caracteres` , minLength(5)),
        maxLength: helpers.withMessage(`El campo debe contener máximo 255 caracteres` , maxLength(255)),
        isUnique: helpers.withMessage(`El correo ya se encuentra registrado`, helpers.withAsync(isUnique)),
        $autoDirty: true 
      }
    };

    let showContainerContact = {
      phone:true,
      cellPhone:true,
      email:true
    };

    function validateRfcStructure(rfc) {
      rfc = rfc.toUpperCase();
      let rfc_pattern =
        "^(([A-ZÑ&]{4})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
        "(([A-ZÑ&]{4})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
        "(([A-ZÑ&]{4})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
        "(([A-ZÑ&]{4})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";
      const coincidences = rfc.match(rfc_pattern);

      const data = [];
      if (coincidences != null) {
        coincidences.forEach((element) => {
          if (typeof element != "undefined") {
            data.push(element);
          }
        });
      }
      return coincidences == null
        ? { status: false, coincidences: data }
        : { status: true, coincidences: data };
    }

    async function validateRfcAlreadyExists(rfc) {
        return await userObjectClass.value.rfcAlreadyExists(
          sessionStorage.getItem("login"), rfc.toString().toUpperCase()
        ).then(res => {
          return !res.data.response.is_already_registered;
        }).catch(err => {
          console.log(err)
          return false;
        });
    }

    let maritalStatus = computed(()=> {
      return (MaritalStatusRef.value != undefined && MaritalStatusRef.value != null)? 
      {
        rfc: MaritalStatusRef.value.maritalStatusModel.rfc,
        paternalName: MaritalStatusRef.value.maritalStatusModel.paternalName,
        maternalName: MaritalStatusRef.value.maritalStatusModel.maternalName,
        firstName: MaritalStatusRef.value.maritalStatusModel.firstName,
        secondName: MaritalStatusRef.value.maritalStatusModel.secondName
      }: null;
    });

    watch(maritalStatus, async (value)=> {
      if(value == null) return;
      await verifyMaritalInformationChanges(value);
    });

    let rfcMaritalIsValid = ref(true);

    async function verifyMaritalInformationChanges(data){
      if(data.rfc == "") return true ;

      let rfc = validateRfcStructure(data.rfc);
      if(rfc.status) {
        rfcMaritalIsValid.value = await validateStrucDataMaritalStatusRFC()
      }
    }

    let rulesValidationsMaritalStatus = ref({
       status:{
        required: helpers.withMessage("El campo es requerido" , requiredIf(true))
       },
       regimeOfGoods:{
        required: helpers.withMessage("El campo es requerido" , requiredIf((value, e)=>{
          // console.log(value)

          return e.status == "Casado(a)"? true: false;
        }))
       }, 
       rfc:{
        required: helpers.withMessage("El campo es requerido" , requiredIf((value, e)=>{
          // console.log(value)

          return e.status == "Casado(a)" ||
                e.status == "Concubinato"
                ? true
                : false;
        })),
        maxlength: helpers.withMessage("El campo tiene que tener maximo 13",maxLength(13)),
        minlength: helpers.withMessage("El campo tiene que tener maximo 13",minLength(13)),
        structureIsValid: helpers.withMessage(
            "El formato del RFC no es correcto. Ejemplo de formato correcto: LOMP8206281H0",
            function (value, e) {
              if(value.length==0) {
                return true;
              }

              if ((e.status == "Casado(a)" || e.status == "Concubinato") && value != "") {

                return rfcMaritalIsValid.value;
              }

              return true;
            }
        ),
        isValid: helpers.withMessage("El formato del RFC no es correcto. Ejemplo de formato correcto: LOMP8206281H0" , helpers.withAsync(async function (value) {
          if(value.length==0) {
            return true;
          }

          let rfc = validateRfcStructure(value);
          return rfc.status;
        })),
        isDinstinct: helpers.withMessage("El RFC no puede ser el mismo que el del socio", function(value, e){
          if ((e.status == "Casado(a)" || e.status == "Concubinato") && value != "") {
            if(PersonalInformationRef.value.personalDataModel.rfc == value){
              return false;
            }
          }

          return true;
        })
       }, 
       dateOfBirth:{
        required: helpers.withMessage("El campo es requerido" , requiredIf(false))
       },
       paternalName:{
        required:helpers.withMessage("El campo es requerido.",function (value, e){
          return ( e.status == "Casado(a)" || e.status == "Concubinato") && e.maternalName == "" && e.paternalName == "" ? false : true;
        }),
        minLength: helpers.withMessage(`El campo debe contener mínimo 2 caracteres`,  minLength(2)),
        maxLength: helpers.withMessage(`El campo debe contener máximo 30 caracteres`,  maxLength(30)),
        validateOnlyLetters: helpers.withMessage("El campo solo acepta letras", (data,index)=> index.paternalName == "" ? true : (new RegExp('^[a-zA-ZéñúóáÑÁÉÓÚíÍ ]+$')).test(index.paternalName)),
        we_have_space_white_start_and_finish:helpers.withMessage(`El campo no puede tener espacios en blanco al inicio y al final.`,(data,index)=>index.paternalName != ""? !new RegExp(/^([\s]{1,})|[-!$%^&*()_+|~=`{}[]:";'<>?,.\/]|([\s]{1,})$/).test(index.paternalName): true),
       },
       maternalName:{
        required:helpers.withMessage("El campo es requerido.",function (value, e){
          return ( e.status == "Casado(a)" || e.status == "Concubinato") && e.maternalName == "" && e.paternalName == "" ? false : true;
        }),
        minLength: helpers.withMessage(`El campo debe contener mínimo 2 caracteres`,  minLength(2)),
        maxLength: helpers.withMessage(`El campo debe contener máximo 30 caracteres`,  maxLength(30)),
        validateOnlyLetters: helpers.withMessage("El campo solo acepta letras", (data,index)=> index.maternalName == "" ? true : (new RegExp('^[a-zA-ZéñúóáÑÁÉÓÚíÍ ]+$')).test(index.maternalName)),
        we_have_space_white_start_and_finish:helpers.withMessage(`El campo no puede tener espacios en blanco al inicio y al final.`,(data,index)=>index.maternalName != ""? !new RegExp(/^([\s]{1,})|[-!$%^&*()_+|~=`{}[]:";'<>?,.\/]|([\s]{1,})$/).test(index.maternalName): true),
       },
       firstName: {
        required:helpers.withMessage("El campo es requerido.", function (value, e){
          return ( e.status == "Casado(a)" ||
                e.status == "Concubinato") && value == "" ? false : true;
        }),
        minLength: helpers.withMessage(`El campo debe contener mínimo 2 caracteres`,  minLength(2)),
        maxLength: helpers.withMessage(`El campo debe contener máximo 30 caracteres`,  maxLength(30)),
        validateOnlyLetters: helpers.withMessage("El campo solo acepta letras", (data,index)=> index.firstName == "" ? true : (new RegExp('^[a-zA-ZéñúóáÑÁÉÓÚíÍ ]+$')).test(index.firstName)),
        we_have_space_white_start_and_finish:helpers.withMessage(`El campo no puede tener espacios en blanco al inicio y al final.`,(data,index)=>index.firstName != ""? !new RegExp(/^([\s]{1,})|[-!$%^&*()_+|~=`{}[]:";'<>?,.\/]|([\s]{1,})$/).test(index.firstName): true),
       },
       secondName:{
        required:helpers.withMessage("El campo es requerido.", function (value, e){
          return true;
        }),
        validateOnlyLetters: helpers.withMessage("El campo solo acepta letras", (data,index)=> index.secondName == "" ? true : (new RegExp('^[a-zA-ZéñúóáÑÁÉÓÚíÍ ]+$')).test(index.secondName)),
        we_have_space_white_start_and_finish:helpers.withMessage(`El campo no puede tener espacios en blanco al inicio y al final.`,(data,index)=>index.secondName != ""? !new RegExp(/^([\s]{1,})|[-!$%^&*()_+|~=`{}[]:";'<>?,.\/]|([\s]{1,})$/).test(index.secondName): true),
       },
       gender:{
        required:helpers.withMessage("El campo es requerido.",requiredIf((value, e)=>{
          return ( e.status == "Casado(a)" ||
                e.status == "Concubinato");
        })),
       }
    });

    let viewClassViewMaritalStatus = {
      input_class:{
        valid:"border-blue-600",
        no_valid:"border-red-600",
        default:"RobotoMedium w-full py-2 px-2 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none"
      },
      div_container_error:{
        container:"my-2",
        text_error:"text-red-600 text-sm",
      },
      div_container:{
        container:"grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-10 mt-10 ml-8",
        label:"w-full RobotoRegular text-base"
      }
    };

    let admissionRequestObject = ref(null);

    let accountType = ref("");

    let identificationFiles = ref("");

    onBeforeMount(async () => {
      isLoading.value = true;
      loadInfo();

      deleteStorage('admissionRequestsinaptissste');
      deleteStorage('identificationFiles');
      
      await getValidationsRules();
      admissionRequestObject.value = new AdmissionRequest();
      documentObjectClass.value = new Document();
      userObjectClass.value = new User();
    });

    onMounted(()=>{
      isLoading.value = false;
    });
    
    async function getValidationsRules() {
      const response = await validations.getStructure();
      rulesValidations.value = response.data.validations;
    };

    function validateCurpStructure(curp) {
      let curp_pattern =
        /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
      const coincidences = curp.match(curp_pattern);
      return coincidences == null ? false : true;
    }

    async function next(){

      isLoading.value = true;
      const IdentificationRegisterRefs = IdentificationRegisterRef.value.execute_validation();
      
      const BirthPlaceRefs = BirthPlaceRef.value.execute_validation();

      const ContactRefs = ContactRef.value.execute_validation();
      
      const PersonalInformationRefs = PersonalInformationRef.value.execute_validation();
      
      const MaritalStatusRefs = MaritalStatusRef.value.execute_validation();

      if(PersonalInformationRefs && MaritalStatusRefs && ContactRefs && BirthPlaceRefs && IdentificationRegisterRefs){
        
        /* const identificationIsNotUnique = await validateIdentificationNumber();

        if (!identificationIsNotUnique) {
          Swal.fire({
            title: "Aviso",
            text: "El número de identificación ya se encuentra registrado",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          SwalAlertHelper.setIdAttributeToModalWindow('pop_upmodal_identification_number_require');
          SwalAlertHelper.setIdAttributeToButtons('button_identification_number_require_ok', '');

          isLoading.value = false;
          return;
        } */
        
        const identificationComplete = identificationFilesComplete();

        if (!identificationComplete) {
          Swal.fire({
            title: "Aviso",
            text: "Favor de adjuntar la Identificación Oficial",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          SwalAlertHelper.setIdAttributeToModalWindow('pop_upmodal_identification_require');
          SwalAlertHelper.setIdAttributeToButtons('button_identification_require_ok', '');

          isLoading.value = false;
          return;
        }
        
        setValuesPersonalInformation(PersonalInformationRefs);
        setValuesMaritalStatus(MaritalStatusRefs);
        setValuesContact(ContactRefs);
        setValuesBirthPlace(BirthPlaceRefs);
        identificationRegisterData(IdentificationRegisterRefs);

        saveInSessionStorage('admissionRequestsinaptissste', admissionRequestObject.value.data);

        let account = getAccountType();
        router.push({path: `/${account}/captura-asistida/sinaptissste/seccion/dos`});
        
      }else{
        console.log("Componentes invalidos!:", PersonalInformationRefs , MaritalStatusRefs , ContactRefs , BirthPlaceRefs , IdentificationRegisterRefs)
      }

      isLoading.value = false;

    }

    async function validateStrucDatauserRFC(){

      if(PersonalInformationRef.value == null) return true;

      let paternalName = PersonalInformationRef.value.personalDataModel.paternalName;
      let maternalName = PersonalInformationRef.value.personalDataModel.maternalName;
      let firstname = PersonalInformationRef.value.personalDataModel.firstName;
      let secondNames = PersonalInformationRef.value.personalDataModel.secondName;

      let seconsName = validateNames(paternalName, maternalName)
      let names = validateNames(firstname, secondNames)
      
      if(PersonalInformationRef.value.personalDataModel.rfc!="" && PersonalInformationRef.value.personalDataModel.dateBirth!="" && names && seconsName && firstname.length > 2 &&  maternalName.length > 2 &&  paternalName.length > 2){
            if (
              !rulesValidationsPersonalInformation.rfc.required ||
              PersonalInformationRef.value.personalDataModel.rfc == "" ||
              !isRequiredRfc.value
            ) {
              return true;
            }

            if (
              PersonalInformationRef.value.personalDataModel.rfc.toString().length < rulesValidationsPersonalInformation.rfc.minLength &&
              !rulesValidationsPersonalInformation.rfc.structureIsValid
            ) {
              return false;
            }
            
            const isValid = await validateCompleteRfc().then(res => {
              return res;
            }).catch(err => {
              console.log("🚀 ~ file: AssistedEnrollmentStep1.vue:726 ~ isValid ~ err:", err)
              return null;
            });

            if(isValid != null && isValid.data.response.has_errors){
              Swal.fire({
                title: "Aviso",
                html: (isValid.data.errors.length > 0)? isValid.data.errors[0] : 'Ha ocurrido un error al validar RFC',
                icon: "error",
              });
              SwalAlertHelper.setIdAttributeToModalWindow('button_modal_cancel');
              SwalAlertHelper.setIdAttributeToButtons('button_modal_ok', '');
              
              return false;
            }
              
            return true;
      }
      return true;
    }

    async function validateStrucDataMaritalStatusRFC(){
      if(MaritalStatusRef.value == null) return true;

      let paternalName = MaritalStatusRef.value.maritalStatusModel.paternalName;
      let maternalName = MaritalStatusRef.value.maritalStatusModel.maternalName;
      let firstname = MaritalStatusRef.value.maritalStatusModel.firstName;
      let secondNames = MaritalStatusRef.value.maritalStatusModel.secondName;

      let secondName = validateNames(paternalName, maternalName)
      let names = validateNames(firstname, secondNames)

      if(MaritalStatusRef.value.maritalStatusModel.rfc!="" && MaritalStatusRef.value.maritalStatusModel.dateBirth!="" && names && secondName && firstname.length > 2 &&  maternalName.length > 2 &&  paternalName.length > 2){
            if (
              !rulesValidationsMaritalStatus.value.rfc.required ||
              MaritalStatusRef.value.maritalStatusModel.rfc == "" ||
              !isRequiredRfcOfMaritalStatus.value
            ) {
              return true;
            }

            if (
              MaritalStatusRef.value.maritalStatusModel.rfc.toString().length < rulesValidationsMaritalStatus.value.rfc.minLength &&
              !rulesValidationsMaritalStatus.value.rfc.structureIsValid
            ) {
              return false;
            }
            
            const isValid = await validateCompleteRfcMaritalStatus().then(res => {
              return res;
            }).catch(err => {
              console.log("🚀 ~ file: AssistedEnrollmentStep1.vue:770 ~ validateCompleteRfcMaritalStatus ~ err:", err)
              
              return null;
            });

            if(isValid != null && isValid.data.response.has_errors){
              Swal.fire({
                title: "Aviso",
                html: (isValid.data.errors.length > 0)? isValid.data.errors[0] : 'Ha ocurrido un error al validar RFC',
                icon: "error",
              });
              SwalAlertHelper.setIdAttributeToModalWindow('button_modal_cancel');
              SwalAlertHelper.setIdAttributeToButtons('button_modal_ok', '');
              
              return false;
            }

        return true;
      }

      return true;
    }

    async function validateCompleteRfc() {
      try {
        let seconName= validateNames( PersonalInformationRef.value.personalDataModel.paternalName,  PersonalInformationRef.value.personalDataModel.maternalName)
        let names= validateNames( PersonalInformationRef.value.personalDataModel.firstName,  PersonalInformationRef.value.personalDataModel.seconName)
        if( PersonalInformationRef.value.personalDataModel.rfc!="" &&  PersonalInformationRef.value.personalDataModel.dateBirth!="" && names && seconName ){
          return await userObjectClass.value
            .rfcFormatIsValid({
              rfc:  PersonalInformationRef.value.personalDataModel.rfc,
              dateBirth:  PersonalInformationRef.value.personalDataModel.dateBirth,
              firstName:  PersonalInformationRef.value.personalDataModel.firstName,
              secondName:  PersonalInformationRef.value.personalDataModel.secondName,
              paternalName:  PersonalInformationRef.value.personalDataModel.paternalName,
              maternalName:  PersonalInformationRef.value.personalDataModel.maternalName,
            })
            .then((res) => {
              return res;
            })
            .catch((err) => {
              return err;
            });
        }
      } catch (error) {
        return false;
      }
    }

    async function validateCompleteRfcMaritalStatus() {
      try {
        let seconName= validateNames( MaritalStatusRef.value.maritalStatusModel.paternalName,  MaritalStatusRef.value.maritalStatusModel.maternalName)
        let names= validateNames( MaritalStatusRef.value.maritalStatusModel.firstName,  MaritalStatusRef.value.maritalStatusModel.seconName)
        if( MaritalStatusRef.value.maritalStatusModel.rfc!="" &&  MaritalStatusRef.value.maritalStatusModel.dateBirth!="" && names && seconName ){
          return await  userObjectClass.value
            .rfcFormatIsValid({
              rfc:  MaritalStatusRef.value.maritalStatusModel.rfc,
              dateBirth:  MaritalStatusRef.value.maritalStatusModel.dateBirth,
              firstName:  MaritalStatusRef.value.maritalStatusModel.firstName,
              secondName:  MaritalStatusRef.value.maritalStatusModel.secondName,
              paternalName:  MaritalStatusRef.value.maritalStatusModel.paternalName,
              maternalName:  MaritalStatusRef.value.maritalStatusModel.maternalName,
            })
            .then((res) => {
              return res;
            })
            .catch((err) => {
              return err;
            });
        }
      } catch (error) {
        return false;
      }
    }

    function validateNames(firstName,secondName){
      if(firstName!="" || secondName!="") {
         return true
      }else{
        return false
      }
    }

    function identificationFilesComplete() {

      if(identificationFiles.value != undefined && identificationFiles.value.length > 0) {
        return true;
      }
      return false;
    }

    const saveInSessionStorage = (name, data) => {
      sessionStorage.setItem(name,  JSON.stringify(data));
    }

    const deleteStorage = (name) => {
      sessionStorage.removeItem(name);
    }

    const setValuesPersonalInformation = (value) => {
      admissionRequestObject.value.data.rfc = value.rfc.toUpperCase();
      admissionRequestObject.value.data.curp = value.curp.toUpperCase();
      admissionRequestObject.value.data.birthday = value.dateBirth;
      admissionRequestObject.value.data.paternal_name = value.paternalName.toUpperCase();
      admissionRequestObject.value.data.maternal_name = value.maternalName.toUpperCase();
      admissionRequestObject.value.data.name = value.firstName.toUpperCase();
      admissionRequestObject.value.data.middle_name = value.secondName.toUpperCase();
      admissionRequestObject.value.data.gender = value.gender;
    }

    const setValuesMaritalStatus = (value) => {

      admissionRequestObject.value.data.marital_status = value.status.toUpperCase();
      admissionRequestObject.value.data.regime = value.regimeOfGoods.toUpperCase();
      admissionRequestObject.value.data.spouse_attributes.rfc = value.rfc.toUpperCase();
      admissionRequestObject.value.data.spouse_attributes.name = value.firstName.toUpperCase();
      admissionRequestObject.value.data.spouse_attributes.middle_name = value.secondName.toUpperCase();
      admissionRequestObject.value.data.spouse_attributes.paternal_name = value.paternalName.toUpperCase();
      admissionRequestObject.value.data.spouse_attributes.maternal_name = value.maternalName.toUpperCase();
      admissionRequestObject.value.data.spouse_attributes.gender = value.gender;

    }

    const setValuesContact = (value) => {
      
      let contact_info_attributes=[];
      let Objet = new Object({
         phone: value.phone.$model,
         cellPhone: value.cellPhone.$model,
         email: value.email.$model,
     })


      if(Objet.phone!=null && Objet.phone!="" && Objet.phone.length==12){
          Objet.phone = Objet.phone.replace(/[\s-]+/g, '');
          let contactInfoAttributes=new Object({
            value: Objet.phone,
            status: "activo",
            extension: "",
            contact_type: "telefono particular"
          });
          contact_info_attributes.push(contactInfoAttributes);
      }

      if(Objet.cellPhone!=null && Objet.cellPhone!="" && Objet.cellPhone.length==12){
          Objet.cellPhone = Objet.cellPhone.replace(/[\s-]+/g, '');
          let contactInfoAttributes=new Object({
            value: Objet.cellPhone,
            status: "activo",
            extension: "",
            contact_type: "celular"
          });
          contact_info_attributes.push(contactInfoAttributes);
      }

      if(Objet.email!="" && Objet.email!=null){
        let contactInfoAttributes=new Object({
          value: Objet.email,
          status: "activo",
          extension: "",
          contact_type: "correo"
        });
        contact_info_attributes.push(contactInfoAttributes);
      }
        
      admissionRequestObject.value.data.contact_info_attributes = Object.assign({}, contact_info_attributes);
    }

    const setValuesBirthPlace = (data) => {
      admissionRequestObject.value.data.country_birth = (data.country == undefined || data.country.toString() == "")? "" : data.country.toUpperCase();
      admissionRequestObject.value.data.state_birth = (data.state == undefined || data.state.toString() == "")? "" : data.state.toUpperCase();
      admissionRequestObject.value.data.municipality_birth = (data.municipality == undefined || data.municipality.toString() == "")? "" : data.municipality.toUpperCase();
      admissionRequestObject.value.data.city_birth = (data.city == undefined || data.city.toString() == "")? "" : data.city.toUpperCase();
    }

    const identificationRegisterData = (data) => {

      let sessionStorageIdentification = sessionStorage.getItem('identification-files');
      sessionStorageIdentification = data.files.files;
      identificationFiles.value = data.files.files;
      sessionStorage.setItem('identificationFiles', JSON.stringify(sessionStorageIdentification));
      
      admissionRequestObject.value.data.identification_type = data.document_type.toString().toUpperCase();
      admissionRequestObject.value.data.identification_number = data.identification_number == "" ? data.passport_number.toString().toUpperCase() :  data.identification_number
    }

    const getAccountType = () => {
      let account = accountType.value;
      switch (account) {
          case "administrator":
            account = "admin";
            break;
          case "member":
            account = "cuenta";
            break;
          case "member_analyst":
            account = "analyst";
            break;
          case "cabsa_member":
            account = "cuenta";
            break;
          case "supervisor":
            account = "supervisor";
            break;
          case "loan_analyst":
            account = "analyst";
            break;
          case "enroller": 
            account="adviser";
          break;
        }

        return account;
    }

    const loadInfo = () => {
      var auth = getTokenDecoden();
      accountType.value = auth.obj["role"];
    };

    const getToken = () => {
      return sessionStorage.getItem('login');
    }

    const buttons_styles = {
      buttonSelected:"",
      activeColorBackground: '#EA7F0B !important',
      inactiveColorBackground: '#FFFFFF !important',
      activeColorText: '#FFFFFF !important',
      inactiveColorText: '#EA7F0B !important',
      activeBorderColor: '#EA7F0B',
      inactiveBorderColor: '#EA7F0B'
    }

    return {
      matiClientId,
      matiuserid,
      viewClassViewPedentityVerification,
      viewClassViewPersonalInformation,
      rulesValidationsPersonalInformation,
      ruleValidationsIdentificationRegister,
      viewClassViewPlaceOfBirth,
      rulesValidationsPlaceOfBirth,
      showInputsPersonalInformation,
      rulesValidationsMaritalStatus,
      viewClassViewMaritalStatus,
      next,
      BirthPlaceRef,
      IdentificationRegisterRef,
      PersonalInformationRef,
      ContactRef,
      MaritalStatusRef,
      viewClassViewPontact,
      rulesValidationsContact,
      showContainerContact,
      getValidationsRules,
      identificationRegisterData,
      viewClassViewIdentificationRegister,
      isLoading,
      ccApi,
      rfcMaritalIsValid,
      buttons_styles
    }
  },

  components: {
    Loading
  }
  
}
</script>
<style scoped>
.btn-next{
  background: #FFC001;
  color: white;
  font-size: 16px;
  font-weight: 400;
}
.sub-title {
  font-size: 24px;
  margin-bottom: 0px;
  font-weight: 400;
}
</style>